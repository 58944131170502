<template>
  <div class="c-app">
    <div class="fix-header px-4">
      <div class="row m-0 p-0 form-inline w-100 h-100">
        <div class="col m-0 p-0">
          <div class="row form-inline">
            <div class="col fs-16 bolder"></div>
          </div>
          <div class="row fs-12" style="visibility: hidden">
            <div class="col-auto form-inline">
              <CIcon name="cil-phone" class="mr-1" />
              {{ $t("Call") }}
            </div>
            <div class="col-auto form-inline">
              <CIcon name="cib-whatsapp" class="mr-1" />
              {{ $t("Whats app") }}
            </div>
          </div>
        </div>
        <div
          class="col-auto m-0 p-0 form-inline fs-24"
          style="display: inline-flex; justify-content: right"
        >
          <img src="/ic_logo.png" alt="Seelfa" height="40" />
          Seelfa
        </div>
      </div>
    </div>
    <div class="scroll-block">
      <div class="row">
        <div class="col-3"></div>
        <div class="col-6">
          <div class="mt-3 fs-16 bold text-center">
            {{ $t("Transaction History") }}
          </div>
          <div class="fs-12 text-center">
            ({{ timeConverter(fromDate) }} - {{ timeConverter(toDate) }})
          </div>
        </div>
        <div
          class="col-3 text-center"
          style="display: flex; align-items: center; padding-left: 2px"
        >
          <b-form-select
            v-model="$i18n.locale"
            :options="langs"
            style="height: 35px; margin-right: 4px; padding-left: 4px"
          ></b-form-select>
        </div>
      </div>
      <div class="summary-block">
        <div class="row">
          <div class="col-auto pr-1">
            <div class="summary-title">{{ $t("Total Debit") }}(-)</div>
            <div class="summary-amount">
              {{ currencySymbol }}{{ round(amountSumGave) }}
            </div>
          </div>
          <div class="col separater"></div>
          <div class="col-auto px-1">
            <div class="summary-title">{{ $t("Total Credit") }}(+)</div>
            <div class="summary-amount">
              {{ currencySymbol }}{{ round(amountSumGot) }}
            </div>
          </div>
          <div class="col separater"></div>
          <div class="col-auto pl-1">
            <div class="summary-title">{{ $t("Net Balance") }}</div>
            <div class="summary-amount">
              <span
                :style="
                  'color:' +
                  (amountSumGave - amountSumGot > 0 ? 'red' : '#0f814d')
                "
              >
                {{ currencySymbol
                }}{{ round(Math.abs(amountSumGave - amountSumGot)) }}</span
              >
              {{
                amountSumGave - amountSumGot > 0
                  ? " " + $t("Dr")
                  : " " + $t("Cr")
              }}
              <div class="fs-12">
                {{
                  this.amountSumGave > this.amountSumGot
                    ? "(" + $t("Customer will give") + ")"
                    : "(" + $t("Customer will get") + ")"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-block">
        <table class="table table-bordered" width="100%">
          <thead>
            <tr>
              <td class="first">{{ $t("Date") }}</td>
              <td>{{ $t("Debit") }}(-)</td>
              <td>{{ $t("Credit") }}(+)</td>
              <td>{{ $t("Balance") }}</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(entry, index) in entries" :key="index">
              <td class="first">
                <div style="color: red">
                  {{ index == 0 ? $t("Latest") : "" }}
                </div>
                {{ timeConverter(entry.entryDate) }}
              </td>
              <td style="background-color: rgba(222, 24, 54, 0.05)">
                {{ entry.amountGave == 0 ? "" : round(entry.amountGave) }}
              </td>
              <td style="background-color: rgba(15, 129, 77, 0.1)">
                {{ entry.amountGot == 0 ? "" : round(entry.amountGot) }}
              </td>
              <td>
                <span
                  :style="'color:' + (entry.balance > 0 ? 'red' : '#0f814d')"
                >
                  <!-- {{ Math.abs(entry.balance) }} -->
                  {{ round(Math.abs(entry.realBalance)) }}
                </span>

                {{ entry.balance > 0 ? " " + $t("Dr") : " " + $t("Cr") }}
              </td>
            </tr>
          </tbody>
        </table>
        <b-modal ref="my-modal" hide-footer hide-header>
          <div class="col-auto m-0 p-0 form-inline fs-24 modal-logo">
            <img src="/ic_logo.png" alt="Seelfa" height="40" />
            Seelfa
          </div>
          <div class="d-block text-center">
            <p>{{ errorMessage }}</p>
          </div>
          <b-button class="mt-3" variant="danger" block @click="hideModal"
            >Please Try Again</b-button
          >
        </b-modal>
        <div v-if="isOnBoard && amountSumDiff > 0">
          <b-button class="button" v-b-modal.modal-1>{{
            $t("Pay Online")
          }}</b-button>
        </div>
        <b-modal
          id="modal-1"
          title="BootstrapVue"
          modal-footer="false"
          hide-header
          hide-footer
        >
          <div class="col-auto m-0 p-0 form-inline fs-24 modal-logo">
            <img src="/ic_logo.png" alt="Seelfa" height="40" />
            Seelfa
          </div>
          <div class="green_box">
            <table>
              <tr>
                <td>{{ $t("Owed") }}</td>
                <td>
                  <strong class="red_color">{{
                    currencySymbol + Math.abs(amountSumGave - amountSumGot)
                  }}</strong>
                </td>
              </tr>
              <tr>
                <td>{{ $t("Pay") }}</td>
                <td>
                  <CButton
                    v-if="!isHidden"
                    @click="isHidden = !isHidden"
                    color="success"
                    size="sm"
                    class="mr-1"
                    >{{ $t("Edit") }}</CButton
                  >
                  <CButton
                    v-if="isHidden"
                    @click="createPaymentIntent"
                    color="success"
                    size="sm"
                    class="mr-1"
                    >{{ $t("Update") }}</CButton
                  >
                  <span v-if="isHidden">{{ currencySymbol }}</span>
                  <input
                    v-model="totalAmount"
                    v-if="isHidden"
                    style="width: 50px"
                  />
                  <strong v-if="!isHidden" class="green_color">{{
                    currencySymbol + totalAmount
                  }}</strong>
                </td>
              </tr>
              <tr>
                <td>{{ $t("Remaining") }}</td>
                <td>
                  {{ remainAmount }}
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <textarea placeholder="Tap to add message"></textarea>
                </td>
              </tr>
              <tr width="50%">
                <td>
                  <button
                    class="button"
                    @click="toggle"
                    :class="[showCreditCard ? 'active' : '']"
                  >
                    {{ $t("Pay with Card") }}
                  </button>
                </td>
                <td width="50%">
                  <button
                    class="button"
                    @click="toggle"
                    :class="[!showCreditCard ? 'active' : '']"
                  >
                    {{ $t("Pay with Bank") }}
                  </button>
                </td>
              </tr>
            </table>

            <div class="mt-2">
              <CCollapse :show="showCreditCard">
                <CCard body-wrapper>
                  <stripe-element
                    type="card"
                    :stripe="STRIPE_PUBLISHABLE_KEY"
                    :elsOptions="elsOptions"
                    @change="cdcompleted = $event.complete"
                  >
                    <template slot-scope="slotProps">
                      <CButton
                        :disabled="!cdcompleted"
                        @click.prevent="payByCard(slotProps)"
                        class="mt-2"
                        color="success"
                      >
                        {{ $t("Pay") }}
                      </CButton>
                    </template>
                  </stripe-element>
                </CCard>
              </CCollapse>
              <CCollapse :show="!showCreditCard">
                <CCard body-wrapper>
                  <div class="mb-2">
                    <div class="row">
                      <div class="col-sm-6">
                        <CInput
                          type="text"
                          v-model="name"
                          id="exampleFormControlInput1"
                          placeholder="Name"
                        />
                      </div>
                      <div class="col-sm-6">
                        <CInput
                          type="email"
                          v-model="email"
                          id="exampleFormControlInput1"
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <stripe-element
                      type="iban"
                      ref="iban"
                      :stripe="STRIPE_PUBLISHABLE_KEY"
                      :elOptions="ibanOptions"
                      @change="ibcompleted = $event.complete"
                    >
                      <template slot-scope="slotProps">
                        <CButton
                          color="success"
                          class="mt-3"
                          :disabled="!ibcompleted"
                          @click="payByIban(slotProps.elements)"
                        >
                          {{ $t("Pay by IBAN") }}
                        </CButton>
                      </template>
                    </stripe-element>
                  </div>
                </CCard>
              </CCollapse>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  CCollapse,
  CButton,
  CCard,
  CInput,
} from "@coreui/vue";
import { StripeElement } from "vue-stripe-better-elements";
import round from "../../common/utils";
import {
  API_URL,
  STRIPE_PUBLISHABLE_KEY,
  STRIPE_TEST_MODE,
} from "../../common/constants";
export default {
  name: "Details",
  components: {
    CCollapse,
    CButton,
    CCard,
    CInput,
    StripeElement,
  },
  data() {
    this.client_secret = "";
    this.orderId = null;
    this.SUCCESS_URL = "/thankyou/";

    return {
      STRIPE_PUBLISHABLE_KEY:"",
      isHidden: false,
      token: null,
      isOnBoard: false,
      stripeCommission: 0,
      stripeAccountId: "",
      totalAmount: 0,
      stripeCustomerId: "",
      elsOptions: {
        locale: "en",
      },
      ibanOptions: {
        supportedCountries: [`SEPA`],
        placeholderCountry: `DE`,
      },
      ibcompleted: false,
      cdcompleted: false,
      currency: "",
      name: "",
      email: "",
      langs: [
        { text: "English", value: "en" },
        { text: "Arabic", value: "ar" },
        { text: "French", value: "fr" },
        { text: "German", value: "de" },
        { text: "Hungarian", value: "hu" },
        { text: "Romanian", value: "ro" },
        { text: "Turkish", value: "tr" },
      ],
      fromDate: "",
      toDate: "",
      currencySymbol: "",
      amountSumGave: "",
      amountSumGot: "",
      amountSumDiff: "",
      visibleLiveDemo: false,
      showCreditCard: true,
      remainAmount: 0,
      errorMessage: "Something Went Wrong",
      businessId: "",
      previousAmount: 0,
      balance: 0,
      entries: [],
    };
  },

  created() {
    this.STRIPE_PUBLISHABLE_KEY = STRIPE_PUBLISHABLE_KEY;
    this.customer_docid = this.$route.params.customer_docid;
    this.loadIpApi();
    this.loadCustomerStatus();
    this.loadEntriesOfCustomer();
  },
  methods: {
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggle() {
      this.showCreditCard = !this.showCreditCard;
    },
    round: round,
    loadIpApi() {
      this.$store.commit("set", ["loading1", true]);
      axios({ url: "https://ipapi.co/json" })
        .then((res) => {
          console.log("ipapi", res);
          this.currency = res.data.currency;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.$store.commit("set", ["loading1", false]);
        });
    },
    loadCustomerStatus() {
      // get isOnBoard, commission & stripeAccountId

      this.$store.commit("set", ["loading2", true]);
      axios({
        method: "POST",
        url: `${API_URL}/express/getCustomerStatus`,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          stripeTestMode: STRIPE_TEST_MODE,
          customerId: this.customer_docid,
        },
      })
        .then((response) => {
          console.log("getCustomerStatus.response", response);
          if (response.data.status) {
            this.isOnBoard = response.data.stripeOnboard;
            this.stripeCommission = response.data.stripeCommission;
            this.stripeAccountId = response.data.stripeAccountId;
            this.businessId = response.data.businessId;
            this.stripeCustomerId = response.data.stripeCustomerId;
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.$store.commit("set", ["loading2", false]);
        });
    },
    loadEntriesOfCustomer() {
      // get customer details from firebase functions
      const clientInfo = {
        meta_name: this.$browserDetect.meta.name,
        meta_version: this.$browserDetect.meta.version,
        meta_ua: this.$browserDetect.meta.ua,
      };
      this.$store.commit("set", ["loading3", true]);
      axios({
        method: "POST",
        url: `${API_URL}/express/getEntriesOfCustomer`,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          cid: this.customer_docid,
          detailLink: window.location.origin + "/#" + this.$route.fullPath,
          clientInfo,
        },
      })
        .then((response) => {
          console.log("getEntriesOfCustomer response", response.data);
          this.currentLang = response.data.lang;
          this.currencySymbol = response.data.currencySymbol;
          this.toDate = response.data.toDate;
          this.fromDate = response.data.fromDate;
          this.amountSumGave = round(response.data.amountSumGave);
          this.amountSumGot = round(response.data.amountSumGot);
          this.amountSumDiff = round(this.amountSumGave - this.amountSumGot);
          console.log("this.amountSumDiff", this.amountSumDiff);
          //this.entries = response.data.entries;
          //console.log('asd', response.data.entries)
          this.$i18n.locale = this.currentLang;
          this.totalAmount = round(
            Math.abs(this.amountSumGave - this.amountSumGot)
          );
          this.previousAmount = this.totalAmount;

          let dataList = [];
          let realBalance = 0;
          let entriesList = response.data.entries.reverse();
          for (let entry of entriesList) {
            realBalance = realBalance + entry.amountGave - entry.amountGot;
            let data = {
              entryDate: entry.entryDate,
              amountGave: entry.amountGave,
              amountGot: entry.amountGot,
              realBalance: realBalance,
            };
            dataList.push(data);
          }
          this.entries = dataList.reverse();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.commit("set", ["loading3", false]);
        });
    },
    createPaymentIntent() {
      console.log("method createPaymentIntent()");
      this.isHidden = !this.isHidden;
      this.previousAmount = Math.abs(this.amountSumGave - this.amountSumGot);
      if (this.totalAmount > this.previousAmount) {
        this.totalAmount = this.previousAmount;
        this.errorMessage = "Entered value is higher than total debt value";
        this.$refs["my-modal"].show();
      }

      if (this.totalAmount == 0) {
        this.totalAmount = this.previousAmount;
        this.errorMessage = "Amount must be grether than 0";
        this.$refs["my-modal"].show();
      }

      this.remainAmount = this.previousAmount - this.totalAmount;
      axios
        .post(
          `${API_URL}/express/createPaymentIntent`,
          {
            stripeTestMode: STRIPE_TEST_MODE,
            amount: this.totalAmount,
            stripeCommission: this.stripeCommission,
            currency: this.currency,
            stripeAccountId: this.stripeAccountId,
          },
          { "Content-Type": "application/json" }
        )
        .then((response) => {
          if (response.data.status) {
            this.client_secret = response.data.clientSecret;
            this.orderId = response.data.orderId;
          } else {
            this.errorMessage = response.data.Message;
            this.$refs["my-modal"].show();
          }
        });
    },
    async payByCard(slotProps) {
      this.cdcompleted = false;
      try {
        this.$store.commit("set", ["loading1", true]);
        console.log("creating token...");
        const token = await slotProps.elements.createToken();
        console.log("token created:", token.token.id);
        console.log("creating payment...");
        let payload = {
          stripeTestMode: STRIPE_TEST_MODE,
          // previousAmount: this.previousAmount,
          amount: this.totalAmount,
          stripeCommission: this.stripeCommission,
          stripeAccountId: this.stripeAccountId,
          token: token.token.id,
          currency: this.currency,
          cid: this.$route.params.customer_docid,
          stripeCustomerId: this.stripeCustomerId,
          // businessId: this.businessId,
        };
        console.log(`${API_URL}/express/payWithStripe`);
        const response = await axios({
          method: "POST",
          url: `${API_URL}/express/payWithStripe`,
          headers: { "Content-Type": "application/json" },
          data: payload,
        });
        console.log("response", response.data);
        if (response.data.status) {
          window.location.href = `/#${this.SUCCESS_URL}${response.data.data}`;
        } else {
          this.errorMessage = response.data.Message;
          this.$refs["my-modal"].show();
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.commit("set", ["loading1", false]);
      }
    },
    payByIban({ confirmSepaDebitPayment }) {
      if (this.email == "" && this.name == "") {
        alert("Fields are required");
        return;
      }
      if (
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email) == false
      ) {
        alert("Invalid Email address");
        return;
      }
      this.cdcompleted = false;
      this.$store.commit("set", ["loading1", true]);
      confirmSepaDebitPayment(this.client_secret, {
        payment_method: {
          sepa_debit: this.$refs.iban.elements.element,
          billing_details: {
            name: this.name,
            email: this.email,
            address: {
              line1: "From backend",
              country: "GB",
            },
          },
        },
      })
        .then((stripeResponse) => {
          console.log("response", stripeResponse);
          // let paymentIntentId = stripeResponse.paymentIntent.id;
          // if (stripeResponse.paymentIntent.status == "processing") {
          // const headers = { "Content-Type": "application/json" };
          // let payload = {
          //   stripeTestMode: STRIPE_TEST_MODE,
          //   previousAmount: this.previousAmount,
          //   amount: this.totalAmount,
          //   cid: this.$route.params.customer_docid,
          //   businessId: this.businessId,
          //   paymentIntentId: paymentIntentId,
          //   orderId: this.orderId,
          // };
          // axios
          //   .post(`${API_URL}/express/updateFirebase`, payload, {
          //     headers,
          //   })
          //   .then((response) => {
          //     console.log("response", response.data);
          //     if (response.data.status) {
          //       window.location.href = `/#${this.SUCCESS_URL}${paymentIntentId}`;
          //     } else {
          //       this.errorMessage = response.data.Message;
          //       this.$refs["my-modal"].show();
          //     }
          //   })
          //   .finally(() => {
          //     window.location.href = `/#${this.SUCCESS_URL}${stripeResponse.paymentIntent.id}`;
          //   });
          // }
          window.location.href = `/#${this.SUCCESS_URL}${stripeResponse.paymentIntent.id}`;
          this.$store.commit("set", ["loading1", false]);
        })
        .catch(console.error);
    },
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.elementRef.submit();
    },
    timeConverter(UNIX_timestamp) {
      var a = new Date(UNIX_timestamp * 1000);
      var months = [
        this.$t("Months.Jan"),
        this.$t("Months.Feb"),
        this.$t("Months.Mar"),
        this.$t("Months.Apr"),
        this.$t("Months.May"),
        this.$t("Months.Jun"),
        this.$t("Months.Jul"),
        this.$t("Months.Aug"),
        this.$t("Months.Sep"),
        this.$t("Months.Oct"),
        this.$t("Months.Nov"),
        this.$t("Months.Dec"),
      ];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var time = date + " " + month + " " + year;
      return time;
    },
  },
};
</script>
<style scoped>
.col.separater {
  display: inline-flex;
  margin: 0 auto;
  padding: 0;
  max-width: 1px;
  background-color: rgba(144, 145, 146, 0.3);
}

.c-app {
  display: flex;
  margin: 0 auto;
  max-width: 480px;
  width: 100%;
}

.fix-header {
  display: flex;
  position: fixed;
  background-color: #4daf4e;
  width: 100%;
  max-width: 480px;
  height: 90px;
  color: white;
}

.scroll-block {
  margin: 90px auto 0 auto;
  position: fixed;
  width: 100%;
  max-width: 480px;
  height: calc(100vh - 92px);
  overflow-x: hidden;
  overflow-y: auto;
  background-color: white;
}

.summary-block {
  margin: 8px;
  padding: 8px 16px;
  border: solid 1px #909192;
  border-radius: 4px;
}

.summary-block .summary-title {
  font-size: 14px;
  color: #909192;
}

.summary-block .summary-amount {
  font-size: 15px;
  color: #2c2c2b;
}

.table-block {
  padding: 0 8px;
  font-size: 12.8px;
}

.table-block table thead tr {
  background-color: #f0f2f4;
  font-weight: bold;
}

.table-block table th,
.table-block table td {
  padding: 8px;
}

.fs-24 {
  font-size: 24px;
}

.fs-16 {
  font-size: 16px;
}

.fs-12 {
  font-size: 12px;
}

.bold {
  font-weight: bold;
}

.bolder {
  font-weight: bolder;
}

td {
  text-align: right;
}

td.first {
  text-align: left;
}

.green_box h4 {
  font-size: 16px;
  line-height: 24px;
  color: #000;
  margin-bottom: 10px;
}

.green_box table {
  width: 100%;
}

.green_box table tr {
  border-bottom: 1px solid #f2f2f2;
}

.green_box table td:first-child {
  text-align: left;
}

.green_box table td {
  padding: 5px 0px;
}

.green_box .red_color {
  color: #ff0000;
}

.green_box .green_color {
  color: green;
}

.button {
  background-color: #2eb85c;
  color: #fff;
  box-shadow: none;
  border: none;
  padding: 7px 15px;
  width: calc(100% - 10px);
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.green_box textarea {
  border: none;
  padding: 5px 0;
  height: 70px;
  font-size: 13px;
  line-height: 18px;
  color: #000;
  font-style: italic;
  width: 100%;
  outline: none !important;
}

.modal-logo {
  display: inline-flex;
  justify-content: right;
  width: 100%;
  background-color: green;
  padding: 10px 15px !important;
  border-radius: 7px;
  color: #fff;
  margin-bottom: 20px !important;
}

button.active {
  background-color: green;
}
</style>
